import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import HomePage from "./pages/HomePage.js";
import MenuPage from "./pages/MenuPage.js";
import ContactsPage from "./pages/ContactsPage.js";
import WinesPage from "./pages/WinesPage.js";
import AboutPage from "./pages/AboutPage.js";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="/menu" element={<MenuPage />} />
        <Route path="/wines" element={<WinesPage />} />
        <Route path="/contacts" element={<ContactsPage />} />
        <Route path="/about" element={<AboutPage />} />~
      </Routes>
    </BrowserRouter>
  );
}

export default App;
