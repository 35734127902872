import React, {useEffect, useState} from 'react'
import gtrezelogo from '../assets/imgs/gtrezelogo.svg';
import gtrezelogoB from '../assets/imgs/gtrezelogoB.svg';
import { slide as Menu } from 'react-burger-menu'
import hamb from '../assets/imgs/hamb.svg';
import close from '../assets/imgs/closehamb.svg';

const Navbar = () => {
  const [menu, setMenu] = useState(false);
  const [windowHref, setWindowHref] = useState(window.location.href);

  var styles = {
    bmMenuWrap: {
      top: '0',
      height: '100vh',
      width: '100vw'
    },
    bmMenu: {
      background: 'hsl(37, 79%, 59%)',
    },
    bmItemList: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    bmItem: {
      display: 'flex',
      fontSize: '1.625rem',
      fontFamily: 'PT Serif, sans-serif',
      lineHeight: '2.153rem',
      fontWeight: '700',
      textAlign: 'center',
      letterSpacing: '0.03em',
      color: 'hsl(20, 60%, 6%)',
      textDecoration: 'none',
      outline: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '5.4vh'
    },
    bmCrossButton: {
      margin: '2.02vh 4.37vw 0 0'
    }
}

  useEffect(() => {
    setWindowHref(window.location.href);
  }, [])

  return (
    <div className="container-nav primary-header">
        <a href="/"><img className={!menu ? 'nav-logo index' : 'nav-logo index'} src={!menu? gtrezelogoB : gtrezelogo} alt="Gtreze" /></a>
        
        <nav className="primary-nav">
        <ul className="nav-list | ff-primary text-primary fw-bold fs-16">
            <li><a className={"btn-border-tertiary btn-tertiary" +((windowHref.includes('about')) ? " pageOn":"")} href="/about">SOBRE NÓS</a></li>
            <li><a className={"btn-border-tertiary btn-tertiary" +((windowHref.includes('contacts')) ? " pageOn":"")} href="/contacts">CONTACTOS</a></li>
            <li><a className={"btn-border-primary btn-primary" +((windowHref.includes('menu')) ? " pageOnMenu":"")} href="/menu">MENU</a></li>
        </ul>
        <div className='mobile'>
          <Menu styles={styles} onStateChange={() => setMenu(!menu)} right customBurgerIcon={ <img src={hamb} alt="hamb"/> } customCrossIcon={ <img src={close} alt="close"/> }>
              <a id="home" className="menu-item" href="/">Início</a>
              <a id="about" className="menu-item" href="/about">Sobre</a>
              <a id="menu" className="menu-item" href="/menu">Menu</a>
              <a id="contact" className="menu-item" href="/contacts">Contactos</a>
          </Menu>
        </div>
        </nav>
    </div>
  )
}

export default Navbar