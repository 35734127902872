import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import about1 from "../assets/imgs/f6.jpeg"
import about2 from '../assets/imgs/f17.jpg'
import about3 from '../assets/imgs/about3.png'
import about32 from '../assets/imgs/about32.svg'
import about4 from '../assets/imgs/f1.jpeg'
import about5 from '../assets/imgs/f16.jpg' // New
import about6 from '../assets/imgs/f5.jpeg'
import about7 from '../assets/imgs/f11.jpeg'

const AboutPage = () => {
  return (
    <div id='top'>
      <header>
          <Navbar/>
      </header>
      <main>
        <section id="contacts">
          <div className='gap-mobilet-120 padding-top-200 flex-center gap-vertical-60'>
            <div className='container-mobile gap-mobile-40 gap-left-120 gap-right-150'>
              <h1 className='title'>SOBRE NÓS</h1>
              <p className='text-white'>
                Situado na <span className="fw-bold">Alameda da Europa</span>, na <span className="fw-bold">Covilhã</span>, 
                o restaurante GTreze é uma opção a considerar em qualquer dia,
                a qualquer hora. Perfeito para almoços de negócios e delicioso 
                para jantares em família ou com amigos, ficar em casa não é opção!
              </p>
            </div>
            <img className='gap-mobile-100 au-image1' src={about1} alt='about1' />
          </div>

          <div className='flex-center gap-mobile-100'>
            <img className='desktop au-image2' src={about2} alt='about2' />
            <div className='container-mobile gap-mobile-40 container-texts gap-left-150 gap-right-240'>
              <p className='text-white'>
                Chegado à Cidade Neve pelas mãos do futebol profissional, 
                e depois de descobrir um novo amor pela Covilhã, Joel Vital 
                aproveitou para transformar as ideias em pratos e os sonhos em realidade.
              </p>
            </div>
            <img className='mobile' src={about2} alt='about2' />
          </div>

          <div className='flex-center gap-mobile-100'>
            <div className='container-mobile gap-mobile-40 container-texts gap-left-150 gap-right-110'>
              <p className='text-white gap-vertical-40 gap-mobile-30'>
                Com uma equipa jovem e dinâmica, irá encontrar no restaurante 
                tudo o que precisa para usufruir de uma experiência inesquecível!
              </p>
              <p className='text-white'>
                Mais do que um restaurante, o GTreze é um ponto de encontro com 
                uma oferta gastronómica rica em paladares intensos. 
                Visite-nos e deixe-se surpreender!
              </p>
            </div>
            <img className='desktop au-image3' src={about3} alt='about3' />
            <img className='mobile au-image3' src={about32} alt='about32' />
          </div>

          <div className='flex-center gap-mobile-100 gap-left-120 gap-vertical-150'>
            <img className='desktop au-image4' src={about4} alt='about4' />
            <div className='container-mobile gap-mobile-40 container-texts gap-left-150 gap-right-240'>
              <div className='gap-vertical-85 gap-mobile-85'>
                <h1 className='title'>MISSÃO E VALORES</h1>
                <p className='text-white'>
                <span className="fw-bold">Valores</span> - Profissionalismo, dedicação e qualidade <br/> 
                <span className="fw-bold">Missão</span> - Oferecer o melhor da gastronomia premium, com requinte, 
                a todos os clientes.
                </p>
              </div>
              <div>
                <h1 className='title'>DE NÓS PARA SI</h1>
                <p className='text-white'>
                Uma aventura iniciada a 20 de fevereiro de 2020, 
                por dois jovens empreendedores com uma paixão em comum: A cozinha.
                </p>
              </div>
            </div>
            <img className='mobile' src={about4} alt='about4' />
          </div>
          <div className='flex-center gap-mobile-100 gap-left-120 gap-vertical-150'>
            <div className='container-mobile gap-mobile-40 container-texts gap-right-150'>
              <h1 className='title'>G•TREZE</h1>
              <p className='text-white'>
              <span className="fw-bold">Treze</span>, inspirado no Joel Vital, conhecido 
                na cidade da Covilhã como profissional de futebol onde envergou a 
                camisola no13 ao longo de nove épocas, retirando-se dos relvados para 
                abraçar este projeto de alma e coração.
              </p>
            </div>
            <img className='au-image5' src={about5} alt='about5' />
          </div>

          <div className='flex-up gap-vertical-200'>
          <img className='desktop au-image6' src={about6} alt='about6' />
          <div className='gap-mobile-100 gap-top-110'>
            <div className='container-mobile gap-mobile-40 container-texts gap-left-275 gap-right-295 gap-vertical-130'>
              <p className='text-white gap-mobile-30 gap-vertical-40'>
                Toda a equipa do nosso restaurante, 
                pretende proporcionar-lhe uma experiência 
                única.
              </p>
              <p className='text-white'>
                Deixe-se surpreender pela criatividade 
                dos Chefes e disfrute da sua refeição.
              </p>
            </div>
            <img className='au-image7' src={about7} alt='about7' />
          </div>
        </div>
        </section>
      </main>
      <footer id="botton">
        <Footer local={"top"}/>
      </footer>
    </div>
  )
}

export default AboutPage