import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Collapsible from '../components/Collapsible'
import foodData from '../assets/data/FoodData.js'
import menuimage from '../assets/imgs/menuimage.png'

const MenuPage = () => {
  return (
    <div id="top">
      <header className='gap-vertical-50'>
          <Navbar/>
      </header>
      <main className='container-mobile'>
        <div className='flex-up padding-top-260 gap-mobilet-120'>
          <div>
          <section id="description">
            <div className="mn-container1 gap-mobile-70">
                <div>
                  <h1 className='title'>MENU EXECUTIVO</h1>
                  <p className="gap-vertical-100 text-white">
                    Cuidadosamente preparado para si, o menu executivo dispõe de uma opção de carne, 
                    peixe, vegetariano ou bowl, para que possa sempre estar do seu agrado. 
                    Apenas está disponível no período do almoço, durante os dias úteis.
                  </p>
                </div>
              </div>
          </section>

          <section id="chart">
            <div className="mn-container1 gap-mobile-30">
              <div>
                <h1 className='title'>CARTA</h1>
                <p className="gap-vertical-100 text-white">
                  Desenvolvida para explorar paladares intensos, a carta está disponível ao almoço 
                  e ao jantar, sendo composta por diferentes opções. Pensado também para os mais novos, 
                  possui um menu infantil para que também possam ter uma boa experiência.
                </p>
              </div>
            </div>
          </section>
          </div>
          <img className='desktop mn-image1' src={menuimage} alt='menuimage' />
        </div>
        <section id="food">
          <div className='container gap-vertical-170 gap-mobile-10'>
            {foodData.map((item, index) => {
              return (
                <Collapsible
                  key={index}
                  title={item.title}
                  content={item.content}
                />
              )
            })}
            <p className='gapClose text-white-small gap-mobile-80'>
              IVA incluído à taxa legal em vigor.
              Nenhum prato, produto alimentar ou bebida, incluindo o couvert, pode ser cobrado se não 
              for solicitado pelo cliente. Se tiver alguma intolerância ou alergia alimentar, 
              por favor, informe-nos.
            </p>
          </div>
        </section>
        {/* <section id="drinks">
          <div className='gap-vertical-130 gap-mobile-200'>
            <div className="flex-center gap-vertical-50 gap-left-130">
              <h1 className='title'>CARTA DE BEBIDAS & VINHOS</h1>              
            </div>
            <div className='container'>
              {drinksData.map((item, index) => {
                return (
                  <Collapsible
                    key={index}
                    title={item.title}
                    content={item.content}
                  />
                )
              })}
            </div>
          </div>
        </section> */}
      </main>
      <footer id="botton">
        <Footer local={"top"}/>
      </footer>
    </div>
  )
}

export default MenuPage