import React, {useEffect, useState} from 'react'
import faceW from '../assets/imgs/facebW.svg';
import messegW from '../assets/imgs/mesW.svg';
import instaW from '../assets/imgs/instaW.svg';
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

const ContactsPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect (() => {
    /* print in console when window width changes*/
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    }
    
}, []);

  return (
    <div id="top">
      <header>
        <Navbar/>
      </header>
      <main>
        <section id="contacts">
          <div className='ct-container container-mobile'>
            <div className='padding-top-260 gap-mobilet-120'>
              <div className='gap-vertical-130'>
                <div className='flex-center gap-vertical-85'>
                  <div>
                    <h1 className='title gap-vertical-50'>CONTACTOS</h1>
                    <div className='flex-up-contacts'>
                      <div>
                        <div className='gap-mobile-30 gap-vertical-50'>
                          <h2 className="text-white-contacts">Telefone</h2>
                          <p className="text-white">+351 913 857 490</p>
                        </div>
                        <div className='gap-mobile-30'>
                          <h2 className="text-white-contacts">Morada</h2>
                          <p className="text-white">
                            Alameda da Europa,{windowWidth > 980 ? <br/> : null} lote 3, loja A<br/>  
                            6200-546 Covilhã
                          </p>
                        </div>
                      </div>
                      <div className='gap-left-200 gap-mobile-30'>
                        <h2 className="text-white-contacts">Horário de Abertura</h2>
                        <p className="text-white">Segunda a Sexta</p>
                        <p className="text-white gap-mobile-20">12:00-15:00 | 19:30-22:30</p>
                        <p className="text-white">Sábado</p>
                        <p className="text-white">12:30-15:00 | 19:30-22:30</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex-down'>
                  <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48706.386263532964!2d-7.4999148!3d40.2724371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd3d2339956809d5%3A0xf4c65130347e9560!2sG.Treze!5e0!3m2!1spt-PT!2spt!4v1671044635110!5m2!1spt-PT!2spt"  className='map gap-mobile-30' loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                  <div className='gap-left-40 gap-mobileb-60 flex-mobile-right'>
                    <div>
                    <p className="text-secondary ff-primary fw-regular fs-16 gap-mobile-10">Contacte-nos!</p>
                    <ul className="social-links social-scale1 flex-mobile-right">
                      <li><a aria-label="facebook" href="https://www.facebook.com/gtrezerestaurante"><img className='icons-scale' src={faceW} alt="facebook"/></a></li>
                      <li><a aria-label="messenger" href="https://www.messenger.com/gtrezerestaurante"><img className='icons-scale' src={messegW} alt="messenger"/></a></li>
                      <li><a aria-label="instagram" href="https://www.instagram.com/gtrezerestaurante"><img className='icons-scale' src={instaW} alt="instagram"/></a></li>
                    </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer id="botton">
        <Footer local={"top"}/>
      </footer>
    </div>
  )
}

export default ContactsPage