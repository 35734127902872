import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import openIm from '../assets/imgs/open.svg';
import openImB from '../assets/imgs/openB.svg';
import closeIm from '../assets/imgs/close.svg';

const Collapsible = (props) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const navigate = useNavigate();

  useEffect(() => {
    setTitle(props.title);
    setContent(props.content);
  }, [props.title, props.content]);

  useEffect (() => {
    /* print in console when window width changes*/
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    }
    
  }, []);

  return (
    <div className={(open ? 'gapOpen' : 'gapClose')}>
      <div className={open ? 'accordion-open gap-mobile-10' : 'accordion gap-mobile-10'} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} >
        <div className={open ? 'itemOpen' : 'item'}>
          <div className={!open ? (windowWidth > 980 ? (!hover ? 'colap-title | text-white-bold' : 'colap-title | text-black-bold-accordion' ) : 'colap-title | text-white-bold') : ('colap-title | text-white-bold gap-vertical-40 gap-mobile-25')} onClick={()=>{title === "Vinhos" ? navigate("/wines") : setOpen(!open) }}>
            <p>{title}</p>
            {open ? <img className='open-close' src={closeIm} alt="close" /> : (windowWidth > 980 ? (hover ? <img className='open-close' src={openImB} alt="openb" /> : <img className='open-close' src={openIm} alt="open" />) : <img className='open-close' src={openIm} alt="open" />)}
          </div>
          <div className={open ? 'content show' : 'content'}>
            {content ? content.map((item, index) => (
              <div className='flex-up gap-vertical-20 gap-mobile-15'>
                <div>
                  <p className={item.category !== ''? 'text-white-category ln-menu' : ''}>{item.category}</p>
                  <p className={item.name !== '' ? 'text-white ln-menu' : ''}>{item.name}</p>
                  <p className={item.description !== '' ? 'text-description' : ''}>{item.description}</p>
                </div>
                <p className='text-white'>{item.price}</p>
              </div>
            )):null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Collapsible