import React, { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import empty from "../assets/imgs/empty.svg";
import full from "../assets/imgs/full.svg";
import f2 from "../assets/imgs/f2.jpeg";
import f3 from "../assets/imgs/f3.jpeg";
import f4 from "../assets/imgs/f4.jpeg";
import f6 from "../assets/imgs/f6.jpeg";
import f7 from "../assets/imgs/f7.jpeg";
import f8 from "../assets/imgs/f8.jpeg";
import f9 from "../assets/imgs/f9.jpeg";
import f11 from "../assets/imgs/f11.jpeg";
import f12 from "../assets/imgs/f12.jpeg";
import f13 from "../assets/imgs/f13.jpeg";
import f14 from "../assets/imgs/f14.jpeg";
import f16 from "../assets/imgs/f16.jpeg";
import f17 from "../assets/imgs/f17.jpeg";

const images = [f2, f3, f4, f6, f7, f8, f9, f11, f12, f13, f14, f16, f17];

const CarouselComp = () => {
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    const [lastIndex, setLastIndex] = React.useState(0);
    const [currentIndex, setCurrentIndex] = React.useState(0);

    const [first, setFirst] = React.useState(false);
    const [second, setSecond] = React.useState(false);
    const [third, setThird] = React.useState(false);

    useEffect (() => {
        const handleResize = () => { setWindowWidth(window.innerWidth); }
        

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
        
    }, []);


    useEffect(() => {
        setLastIndex(currentIndex);
    }, [currentIndex]);


    const onChange = (index, item) => {
        /* const slide = document.querySelector('.carousel .slide');
        const previous = document.querySelector('.carousel .previous'); */
        setCurrentIndex(index);

        if(index === 0 || index === 12) {
            setFirst(false);
            setSecond(false);
            setThird(false);
        }
        else {
            setFirst(index >= 1 && index < 5);
            setSecond(index >= 5 && index < 8);
            setThird(index >= 8 && index < 12);
        }

        /* if(lastIndex < index) {
            console.log('right');
            slide.style.alignContent = 'flex-start';
            previous.style.alignContent = 'flex-end';
        }
        else {
            console.log('left');
            slide.style.alignContent = 'flex-end';
            previous.style.alignContent = 'flex-start';
        } */

    }

    return (
    <div>
        <Carousel
            centerMode={true}
            centerSlidePercentage={windowWidth > 980 ? 51.3 : 87.2}
            showThumbs={false}
            infiniteLoop={true}
            showStatus={false}
            showIndicators={false}
            emulateTouch={true}
            showArrows={windowWidth > 980 ? true : false}
            autoPlay={windowWidth > 980 ? true : false}
            stopOnHover={windowWidth > 980 ? true : false}
            transitionTime={600}
            onChange={(index, item) => onChange(index, item)}
        >
            {images.map((image, index) => (
                <div>
                    <img src={image} key={index} alt={image} />
                </div>
            ))}
        </Carousel>
        <div className="flex-dots">
            <img className={"dots "+(currentIndex !== 0 && currentIndex >= 5 ? "small" : "")} src={currentIndex === 0 ? full : empty} alt="1" />
            <img className="dots" src={first ? full : empty} alt="2" />
            <img className="dots" src={second ? full : empty} alt="3" />
            <img className="dots" src={third ? full : empty} alt="4" />
            <img className={"dots "+(currentIndex !== 12 && currentIndex <= 7 ? "small" : "")} src={currentIndex === 12 ? full : empty} alt="5" />
        </div>
    </div>
    );
};

export default CarouselComp;
