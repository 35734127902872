const winesData = [
    {
        title: 'Vinho Branco',
        content: [
            {
                category: 'BEIRA',
                name: 'Quinta dos Termos Doc',
                description: '',
                price: '11.00€'
            },
            {
                category: '',
                name: 'Beyra Reserva',
                description: '',
                price: '16.50€'
            },
            {
                category: '',
                name: 'Quinta dos Termos Riesling',
                description: '',
                price: '17.00 €'
            },
            {
                category: '',
                name: 'Casas Altas Vale Ruivo',
                description: '',
                price: '17.50€'
            },
            {
                category: '',
                name: 'Casas Altas Chardonay',
                description: '',
                price: '21.00€'
            },
            {
                category: 'DOURO',
                name: 'Conde Monsul',
                description: '',
                price: '11.00€'
            },
            {
                category: '',
                name: 'Lua Cheia Vinhas Velhas',
                description: '',
                price: '14.50€'
            },
            {
                category: '',
                name: 'Terras do Grifo',
                description: '',
                price: '16.00€'
            },
            {
                category: '',
                name: 'Bulas',
                description: '',
                price: '16.00€'
            },
            {
                category: '',
                name: 'Palato Colheita',
                description: '',
                price: '16.00€'
            },
            {
                category: '',
                name: 'Quinta Nova Pomares',
                description: '',
                price: '17.00€'
            },
            {
                category: '',
                name: 'Crasto Doc',
                description: '',
                price: '17.00€'
            },
            {
                category: '',
                name: 'Quinta Sá de Baixo',
                description: '',
                price: '18.00€'
            },
            {
                category: '',
                name: 'Mateus Nicolau de Almeida',
                description: '',
                price: '20.00€'
            },
            {
                category: '',
                name: 'Grainha',
                description: '',
                price: '23.00€'
            },
            {
                category: 'BAIRRADA',
                name: 'São Lourenço',
                description: '',
                price: '14.00€'
            },
            {
                category: '',
                name: 'Marquês de Marialva Arinto Reserva',
                description: '',
                price: '15.00€'
            },
            {
                category: 'DÃO',
                name: 'Taboadella Villae',
                description: '',
                price: '16.00€'
            },
            {
                category: '',
                name: 'Casa de Albuquerque Encruzado',
                description: '',
                price: '16.50€'
            },
            {
                category:'',
                name: 'Bella Elegance Souvignon Blanc',
                description: '',
                price: '29.00€'
            },
            {
                category:'ALENTEJO',
                name: 'Herdade dos Grous',
                description: '',
                price: '18.50€'
            },
            {
                category:'',
                name: 'Pêra Manca',
                description: '',
                price: '90.00€'
            }
        ]
    },
    {
        title: 'Vinho Branco a Copo',
        content: [
            {
                category:'',
                name: 'Vinho Branco 2.5',
                description: '',
                price: '3.50€'
            },
            {
                category:'',
                name: 'Lua cheia vinhas velhas',
                description: '',
                price: '5.00€'
            }
        ]
    },
    {
        title: 'Vinho Verde',
        content: [
            {
                category:'',
                name: 'Quinta da Aveleda Alvarinho e Loureiro',
                description: '',
                price: '15.00€'
            },
            {
                category:'',
                name: 'Soalheiro Granit',
                description: '',
                price: '17.00€'
            }
        ]
    },
    {
        title: "Vinho Rosé",
        content: [
            {
                category:'BEIRA',
                name: 'Quinta dos Termos',
                description: '',
                price: '11.00€'
            },
            {
                category:'',
                name: 'Casas Altas Vale Ruivo',
                description: '',
                price: '17.00€'
            },
            {
                category:'DOURO',
                name: 'Conde MonsuL',
                description: '',
                price: '11.00€'
            },
            {
                category:'',
                name: 'Terras do Grifo',
                description: '',
                price: '16.00€'
            } 
        ]
    },
    {
        title: 'Espumante',
        content: [
            {
                category:'BEIRA',
                name: 'Quinta dos Termos Bruto',
                description: '',
                price: '19.00€'
            },
            {
                category:'BAIRRADA',
                name: 'São Domingues Extra Reserva Bruto',
                description: '',
                price: '19.00€'
            }
        ]
    },
    {
        title: 'Vinho Tinto',
        content: [
            {
                category:'BEIRA',
                name: 'Quinta dos Termos Doc',
                description: '',
                price: '11.00€'
            },
            {
                category:'',
                name: 'Beyra Reserva',
                description: '',
                price: '16.50€'
            },
            {
                category:'',
                name: 'Casa Altas Vale Ruivo',
                description: '',
                price: '17.50€'
            },
            {
                category:'',
                name: 'Quinta dos Termos Seleção',
                description: '',
                price: '17.50€'
            },
            {
                category:'',
                name: 'Quinta dos Termos Vinhas Velhas',
                description: '',
                price: '17.50€'
            },
            {
                category:'',
                name: 'Quinta dos Termos Rufete Talhão da Serra',
                description: '',
                price: '21.00€'
            },
            {
                category:'',
                name: 'Quinta dos Termos Vinha das Colmeias',
                description: '',
                price: '23.00€'
            },
            {
                category:'DOURO',
                name: 'Conde Monsul',
                description: '',
                price: '11.00€'
            },
            {
                category:'',
                name: 'Terras do Grifo',
                description: '',
                price: '16.00€'
            },
            {
                category:'',
                name: 'Palato Colheita',
                description: '',
                price: '16.00€'
            },
            {
                category:'',
                name: 'Quinta Nova Nossa Senhora do Carmo',
                description: '',
                price: '17.00€'
            },
            {
                category:'',
                name: 'Quinta do Crasto Doc',
                description: '',
                price: '17.00€'
            },
            {
                category:'',
                name: 'Quinta do Crasto Superior',
                description: '',
                price: '27.00€'
            },
            {
                category:'',
                name: 'Qta Nova N. Sra do Carmo Reserva Terroir Blend',
                description: '',
                price: '32.00€'
            },
            {
                category:'',
                name: 'Quanta Terra',
                description: '',
                price: '36.00€'
            },
            {
                category:'',
                name: 'Palato Reserva',
                description: '',
                price: '35.00€'
            },
            {
                category:'',
                name: 'Quinta do Crasto Vinhas Velhas',
                description: '',
                price: '45.00€'
            },
            {
                category:'TRÁS-OS-MONTES',
                name: 'Quinta do Sobreiró de Cima Reserva',
                description: '',
                price: '16.00€'
            },
            {
                category:'BAIRRADA',
                name: 'São Lourenço',
                description: '',
                price: '14.50€'
            },
            {
                category:'',
                name: 'Quinta dos Abibes Reserva',
                description: '',
                price: '21.00€'
            },
            {
                category:'DÃO',
                name: 'Casa Albuquerque Touriga Nacional',
                description: '',
                price: '18.00€'
            },
            {
                category:'',
                name: 'Quinta das Marias',
                description: '',
                price: '19.50€'
            },
            {
                category:'',
                name: 'Casa de Mouraz Bio',
                description: '',
                price: '20.00€'
            },
            {
                category:'',
                name: 'Musgo',
                description: '',
                price: '25.00€'
            },
            {
                category:'ALENTEJO',
                name: 'Crescendo',
                description: '',
                price: '16.00€'
            },
            {
                category:'',
                name: 'Reynolds',
                description: '',
                price: '17.00€'
            },
            {
                category:'',
                name: 'Herdade dos Grous',
                description: '',
                price: '20.00€'
            }
        ]
    },
    {
        title: 'Vinho Tinto a Copo',
        content: [
            {
                category:'',
                name: 'Vinho Região da Beira - 2.5',
                description: '',
                price: '3.50€'
            },
            {
                category:'',
                name: 'Vinho Região do Douro - Crasto doc',
                description: '',
                price: '6.50€'
            }
        ]
    },
    {
        title: 'Vinho do Porto',
        content: [
            {
                category:'',
                name: 'Rozés Tawny',
                description: '',
                price: '3.50€'
            },
            {
                category:'',
                name: 'Rozés Dry White',
                description: '',
                price: '3.50€'
            }
        ]
    }
]

export default winesData