const foodData = [
    {
        title: 'Entradas',
        content: [
            {
                category: '',
                name: 'Sopa do Dia',
                description: '',
                price: ''
            },
            {
                category: '',
                name: 'Couvert',
                description: 'PÃO, MANTEIGAS, PALITOS CROCANTES E AZEITE',
                price: ''
            },
            {
                category: '',
                name: 'Sopa de Peixe',
                description: '',
                price: ''
            },
            {
                category: '',
                name: 'Cogumelo Portobello Recheado',
                description: 'BACON E QUEIJO PARMESÃO',
                price: ''
            },
            {
                category: '',
                name: 'Crocante de Alheira',
                description: '',
                price: ''
            },
            {
                category: '',
                name: 'Tártaro de Novilho',
                description: '',
                price: ''
            },
            {
                category: '',
                name: 'Corneto de Sapateira',
                description: '',
                price: ''
            },
            {
                category: '',
                name: 'Camarão, Coco e Abóbora',
                description: '',
                price: ''
            },
            {
                category: '',
                name: 'Ceviche de Bacalhau Fresco',
                description: '',
                price: ''
            },
            {
                category: '',
                name: 'Camarão ao Alho',
                description: '',
                price: ''
            }
        ]
    },
    {
        title: 'Menu Infantil',
        content: [
            {
                category: '',
                name: 'Sopa do Dia',
                description: '',
                price: ''
            },
            {
                category: '',
                name: 'Mini Hamburguer',
                description: 'CARNE DE NOVILHO, BATATAS FRITAS, QUEIJO, ALFACE E TOMATE',
                price: ''
            },
            {
                category: '',
                name: 'Sobremesa',
                description: '',
                price: ''
            },
            {
                category: '',
                name: 'Menu Infantil Completo',
                description: '',
                price: ''
            }
        ]
    },
    {
        title: 'Risottos',
        content: [
            {
                category: '',
                name: 'Risotto de Cogumelos, Bacon e Espargos',
                description: '',
                price: ''
            },
            {
                category: '',
                name: 'Risotto à Bulhão Pato',
                description: '',
                price: ''
            },
            {
                category: '',
                name: 'Risotto Negro de Camarão e Lulas',
                description: '',
                price: ''
            },
            {
                category: '',
                name: 'Risotto de Espumante, Lima e Camarão',
                description: '',
                price: ''
            },
        ]
    },
    {
        title: 'Vegetariano',
        content: [
            {
                category: '',
                name: 'Tofu e Legumes',
                description: '',
                price: ''
            },
            {
                category: '',
                name: 'Risotto de Beterraba e Espargos',
                description: '',
                price: ''
            },
            {
                category: '',
                name: 'Linguini Negro de Legumes',
                description: '',
                price: ''
            }
        ]
    },
    {
        title: 'Peixes',
        content: [
            {
                category: '',
                name: 'Linguini de Camarão',
                description: '',
                price: ''
            },
            {
                category: '',
                name: 'Arroz de Tamboril e Gambas',
                description: '',
                price: ''
            },
            {
                category: '',
                name: 'Corvina e Gelado de Caril',
                description: '',
                price: ''
            },
            {
                category: '',
                name: 'Bacalhau Fresco e Bacon',
                description: '',
                price: ''
            },
            {
                category: '',
                name: 'Peixe do Dia e Camarão no Forno',
                description: '',
                price: ''
            }
        ]
    },
    {
        title: 'Carnes',
        content: [
            {
                category: '',
                name: 'Vazia',
                description: 'MIGAS DE BACON E BATATA FRITA ',
                price: ''
            },
            
            {
                category: '',
                name: 'T-Bone (2 Pessoas)',
                description: '15 DIAS DE MATURAÇÃO, RISOTTO DE COGUMELOS',
                price: ''
            },
            {
                category: '',
                name: 'Chuletón (2 Pessoas)',
                description: '35 DIAS DE MATURAÇÃO, RISOTTO DE COGUMELOS',
                price: ''
            },
            {
                category: '',
                name: 'Tomahawk (2 Pessoas)',
                description: '15 DIAS DE MATURAÇÃO, RISOTTO DE COGUMELOS',
                price: ''
            },
            
            
            
        ]
    },
    {
        title: 'Acompanhamentos Extra',
        content: [
            {
                category: '',
                name: 'Batata Frita, Arroz Branco, Feijão Preto ou Salada',
                description: '',
                price: ''
            },
            {
                category: '',
                name: 'Risotto de Cogumelos',
                description: '',
                price: ''
            }
        ]
    },
    {
        title: 'Sobremesas',
        content: [
            {
                category: '',
                name: 'Fruta Laminada',
                description: '',
                price: '6.90€'
            },
            {
                category: '',
                name: '"As nossas Natas do Céu"',
                description: '',
                price: ''
            },
            {
                category: '',
                name: 'Menta e Eucalipto',
                description: '',
                price: ''
            },
            {
                category: '',
                name: 'Maçã e Mascarpone',
                description: '',
                price: ''
            },
            {
                category: '',
                name: 'Chocolate e Amendoim',
                description: 'IOGURTE, BANANA E CARAMELO SALGADO',
                price: ''
            },
            
        ]
    }
]

export default foodData