import {React, useEffect} from "react";
import leftimage from '../assets/imgs/leftimage.jpg';
import rightimage from '../assets/imgs/rightimage.png';
import menuimage from '../assets/imgs/menuimage.png';
import mobileimage from '../assets/imgs/homeMobile.png';
import scroll from '../assets/imgs/scroll.svg';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CarouselComp from "../components/Carousel";

const HomePage = () => {

  useEffect(() => {
    const calculateVh = () => {
      var vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', vh + 'px');
    }

    // Initial calculation
  calculateVh();

  // Re-calculate on resize
  window.addEventListener('resize', calculateVh);

  // Re-calculate on device orientation change
  window.addEventListener('orientationchange', calculateVh);
  }, [])  

  return (
    <div id="top">
      <header>
        <Navbar/>
      </header>
      <main>
        <section id="reservation">
          <div className="bg-image gap-vertical-85">
            <div className="flex-columns">
              <a href="tel:+351913857490" className="gap-vertical-50 gap-mobile-20 btn-border-secondary btn-secondary ff-primary text-secondary fw-medium fs-16">Reserve Agora</a>
              <a className="mobile gap-mobileb-60" href={"#first"}><img src={scroll} alt="scroll" /></a>
            </div>
          </div>
        </section>
        <div id="first"></div>
        <section >
          <div className="flex-center gap-mobilet-110">
            <img className="desktop hp-image1" src={leftimage} alt="leftimage" />
            <div className="container-mobile gap-left-150 gap-right-240">
              <h1 className="title fs-35">BEM-VINDO AO G. TREZE!</h1>
              <p className="text-white gap-vertical-50 gap-mobile-10">
                Situado na <span className="fw-bold">Alameda da Europa</span>, na <span className="fw-bold">Covilhã</span>, o restaurante GTreze 
                é uma opção a considerar em qualquer dia, a qualquer hora. Perfeito 
                para almoços de negócios e delicioso para jantares em família ou com amigos, 
                ficar em casa não é opção!
              </p>
              <div className="align-end"><a className="btn-border-primary btn-secondary ff-primary text-secondary fw-medium fs-16" href="/about">+ Sobre Nós</a></div>
            </div>
            <img className=" gap-mobilet-60 mobile" src={mobileimage} alt="leftimage" />
          </div>
        </section>

        <section id="kitchen">
          <div className="flex-center gap-mobilet-110 gap-vertical-200 gap-left-120">
            <div className="container-mobile gap-right-150 hp-container1 gap-mobileb-60">
              <h1 className="title fs-35">COZINHA</h1>
              <p className="text-white gap-vertical-50 gap-mobile-10">
                Mais do que um restaurante, o GTreze é um ponto de 
                encontro com uma <span className="fw-bold">oferta gastronómica</span> rica em paladares 
                intensos. Visite-nos e deixe-se surpreender!
              </p>
              <div className="align-end"><a className="btn-border-primary btn-secondary ff-primary text-secondary fw-medium fs-16" href="/menu">Oferta Gastronómica</a></div>
            </div>
            <div className="desktop hp-container2">
              <div className="align-end"><img className="hp-image2" src={menuimage} alt="menuimage"/></div>
              <div className="align-left"><img className="hp-image3" src={rightimage} alt="leftimage"/></div>
            </div>
          </div>
        </section>

        <section className="carousel gap-vertical-200 gap-mobilev-130">
          <CarouselComp/>
        </section>

      </main>
      <footer id="botton">
        <Footer local={"reservation"}/>
      </footer>
    </div>
  );
};

export default HomePage;
