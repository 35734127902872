import React from 'react'
import winesData from '../assets/data/WinesData'
import Collapsible from '../components/Collapsible'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

const WinesPage = () => {
  return (
    <div>
      <header id="top" className='gap-vertical-50'>
        <Navbar/>
      </header>
      <main>
        <section id="drinks">
          <div className='gap-vertical-130 gap-top-260'>
            <div className="flex-center gap-left-130">
              <h1 className='title'>VINHOS</h1>              
            </div>
            <div className='container'>
              {winesData.map((item, index) => {
                return (
                  <Collapsible
                    key={index}
                    title={item.title}
                    content={item.content}
                  />
                )
              })}
            </div>
          </div>
        </section>
      </main>
      <footer id="botton">
        <Footer/>
      </footer>
    </div>
  )
}

export default WinesPage